import React, { useEffect } from "react";
import { useLocation } from '@gatsbyjs/reach-router';
import { useTranslation } from 'react-i18next';
import { ParallaxProvider } from 'react-scroll-parallax';

import PostWrapper from './PostWrapper';
import useComponentWillMount from 'src/hooks/useComponentWillMount';
import setLanguage from 'src/utils/setLanguage';
import replaceAmpersand from 'src/utils/replaceAmpersand';
// import { MEDIUM_BREAKPOINT } from 'src/utils/breakpoints';
import DefaultHead from 'src/components/head-defaults';
import Seo from 'src/components/seo';

const Post = ({ pageContext }) => {
  const location = useLocation();
  const {i18n} = useTranslation();
  useComponentWillMount(() => {setLanguage(location.pathname, i18n)});
  useEffect(() => {
    localStorage.setItem('articleFallbackUrl', '');
  }, []);

  return (
    <div className="post-page-wrapper">
      <ParallaxProvider>
        <PostWrapper {...{ pageContext }} />
      </ParallaxProvider>
    </div>
  );
};

export default Post;

export const Head = ({ pageContext }) => {
  const { title } = pageContext;
  const postHeroObj = pageContext.components.contents.find(c => c.fieldGroupName === "post_Components_Contents_ArticleHero");


  /*
        {postHeroObj && postHeroObj.heroImage && postHeroObj.heroImage.sourceUrl 
        && <link rel="preload" as="image" href={postHeroObj.heroImage.sourceUrl} media={`(min-width: ${MEDIUM_BREAKPOINT}px)`}></link> }
      {postHeroObj && postHeroObj.mobileHeroImage && postHeroObj.mobileHeroImage.sourceUrl 
        && <link rel="preload" as="image" href={postHeroObj.mobileHeroImage.sourceUrl} media={`(max-width: ${MEDIUM_BREAKPOINT - 1}px)`}></link> }

  */
  return (
    <>
      <DefaultHead />

      <Seo
        title={replaceAmpersand(title) || "Untitled"}
        description={postHeroObj && postHeroObj.byline}
        imageUrl={postHeroObj && ( ( postHeroObj.socialHeroImage && postHeroObj.socialHeroImage.sourceUrl ) || ( postHeroObj.heroImage && postHeroObj.heroImage.sourceUrl ) )}
        articleUrl={pageContext.url}
        articleLang={pageContext.language.slug}
        pageContext={pageContext}
        type="article"
      />
    </>
  )
}
