import React from "react";
// import Cookies from 'js-cookie';
// import { useParallaxController } from 'react-scroll-parallax';
// import { isAuthenticated } from "src/utils/auth";
import replaceAmpersand from 'src/utils/replaceAmpersand';
import Layout from "src/components/Layout";
import PostLayouts from "src/components/PostLayouts";
import PageLimitModal from 'src/components/PageLimitModal/PageLimitModal';

const filterCategories = categories => {
  const cats = categories.nodes.length ? categories.nodes : null

  if (!cats) {
    return null
  }

  return cats.filter(
    cat => cat.name !== "Uncategorized" && cat.name !== "Featured"
  )
}

const PostWrapper = (props) => {
  // const parallaxController = useParallaxController();
  const updateParallaxState = () => { /* parallaxController.update(); */ }
  const { pageContext } = props;
  const { title, components, categories } = pageContext;
  const postHeroObj = pageContext.components.contents.find(c => c.fieldGroupName === "post_Components_Contents_ArticleHero");
  const layouts = components.contents || [];
  
  const pageLimitModal = false;
  /*
  const [pageLimitModal, setPageLimitModal] = useState(false);
  const incAriclesViewedCount = () => {
    // Increase articles viewed count 
    updateParallaxState()
    const articlesViewCount = +Cookies.get('articles_view_count') || 1;
    if(articlesViewCount >= 6 && !isAuthenticated()) {
      setPageLimitModal(true);
    }
    else {
      Cookies.set('articles_view_count', articlesViewCount ? articlesViewCount + 1 : 1);
    }
  }
  */

  return (
    <Layout
      theme={pageContext.themeSelect.themeSelect}
      title={replaceAmpersand(title) || "Untitled"}
      articleImageUrl={postHeroObj && postHeroObj.heroImage && postHeroObj.heroImage.sourceUrl}
      isArticlePage
    >
      {layouts.filter(l => l.fieldGroupName && l.fieldGroupName !== "post_Components_Contents_CircleThumbnail").map((layout, index) => (
        <PostLayouts
          key={index}
          slug={pageContext?.slug}
          layoutData={layout}
          categories={filterCategories(categories)}
          theme={pageContext.themeSelect.themeSelect}
          updateParallaxState={updateParallaxState}
        />
      ))}
      {pageLimitModal && <PageLimitModal articleUrl={pageContext.url} />}
    </Layout>
  )
}

export default PostWrapper;
